import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import AdminHeader from "../layout/admin-header.jsx";
import * as PR from "../../prime-modules/index";
import "./EsimDetails.scss";
import AdminFooter from "../layout/admin-footer.jsx";

const SearchBox = () => {
  const navigate = useNavigate();
  const toast = useRef(null);
  const [searchTerm, setSearchTerm] = useState("");

  const handleSearch = () => {
    if (!searchTerm.trim()) {
      toast.current.show({
        severity: "warn",
        summary: "Validation Error",
        detail: "Please enter a valid ICCID.",
      });
      return;
    }
    navigate(`/esim-details/${searchTerm}`);
  };

  return (
    <div className="main">
      <div className="layout-sidebar">
        <AdminHeader />
      </div>
      <div className="layout-content-wrapper">
        <section className="iccid-search-section">
          <div className="search-block">
            <h2>Enter the ICCID</h2>
            <div className="p-inputgroup">
              <PR.InputText
                value={searchTerm}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setSearchTerm(numericValue)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter") {
                    handleSearch();
                  }
                }}
                placeholder="Search by ICCID"
                className="p-inputtext-lg"
              />
              <span className="p-inputgroup-addon">

                  <i
                    className="pi pi-search"
                    style={{ cursor: "pointer" }}
                    onClick={handleSearch}
                  />
              </span>
            </div>
          </div>
        </section>
        <AdminFooter />
      </div>
      <PR.Toast ref={toast} />
    </div>
  );
};

export default SearchBox;
