import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { checkDataSize, modifyDate, setPrice } from "../../utils/reuse";
import * as PR from "../../prime-modules/index";
import warning_icon from "../../assets/images/warning_icon.png";
import { getOrderInfo } from "../../services/api";
import { useDispatch } from "react-redux";

const BundleDetails = ({bundle}) => {
  const {sessionId} = useSelector((state) => state.adminAuth.adminSessionData);
  const dispatch = useDispatch();
  const toast = useRef();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState(undefined)
  const headers = useMemo(() => {
    return { sessionid: sessionId };
  }, [sessionId]);

  const getEsimInfo = useCallback(() => {
   setLoading(true);
    let getResponse = (response) => {
    setLoading(false);
      if (response.result === "SUCCESS") {
        setData(response.data)
          setLoading(false);
      } else {
      setLoading(false);
        const error = response.error;
        toast.current?.show({
          severity: error.severity,
          summary: "Error",
          detail: error.errorMsg ? error.errorMsg : error.summary,
        });
      }
    };
    let data = {
      orderId:bundle?.orderId
    }
    getOrderInfo(data,dispatch,headers, getResponse);
  }, [headers, dispatch, bundle?.orderId]);

  useEffect(() => {
    getEsimInfo();
    return () => {};
  }, [bundle?.orderId, getEsimInfo]);
  const BundleExpiry = () => {
    let expiryDate = moment(bundle?.bundleEndTime).format("DD/MM/YYYY");
    let lastDate = new Date(expiryDate.split("/").reverse().join("-"));
    return expiryDate === "01/01/0001" ? null : (
      <div className="warning-msg flex gap-2 align-items-center">
        <PR.Image src={warning_icon} alt="Warning" />
        <p>
          {bundle?.state === "expired"
            ? "Expired"
            : `Expiring in
          ${Math.ceil((lastDate - new Date()) / (1000 * 60 * 60 * 24))} days
          ${expiryDate}`}
        </p>
      </div>
    );
  };
  return (
    <div className="main">
      <section className="signup-section myplans-section">
        <div className="grid">
          <div className="col-12">
            {loading || data === undefined ? (
              <div className="loading-div text-center mt-8">
                <i
                  className="pi pi-spin pi-spinner"
                ></i>
              </div>
            ) : (
              <div className="plans-grid-section">
                <div className="bundle-details-section">
                  <div className="grid bundles-list">
                    <div className="col-12 md:col-12 lg:col-4">
                      <div className="sticky-block">
                        <p className="heading-p">Bundle details</p>
                        <div className="bundle">
                          <div className="flex align-items-center gap-2">
                            <div className="dot">
                              <span
                                className={
                                  bundle?.state === "active" ||
                                  bundle?.state === "expired"
                                    ? bundle?.state
                                    : "pending"
                                }
                              ></span>
                            </div>
                            <div>
                              <h2>{bundle?.tag} </h2>
                              <p>ICCID: {data?.iccid}</p>
                            </div>
                          </div>

                          <div className="expiry-column">
                            <p></p>
                            <div className="card mt-1"></div>
                          </div>
                          <div className="flex align-items-center justify-content-between mt-4">
                            <h4>
                              {bundle?.isUnlimited
                                ? "Unlimited"
                                : `${`${checkDataSize(
                                    bundle?.remainingData
                                  )}`} / ${checkDataSize(
                                    bundle?.totalData
                                  )} left`}
                            </h4>
                          </div>
                          <div className="card mt-2">
                            <PR.ProgressBar
                              value={
                                bundle?.totalData
                                  ? (bundle?.remainingData /
                                      bundle?.totalData) *
                                    100
                                  : 0
                              }
                              className="progress-bar"
                            ></PR.ProgressBar>
                          </div>
                          {BundleExpiry()}
                        </div>
                      </div>
                    </div>
                    <div className="col-12 md:col-12 lg:col-8">
                      <p className="heading-p">Order details</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>Order ID</li>
                          <li>{data?.orderId}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Purchased on</li>
                          <li>{modifyDate(data?.createdTs)}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Order Value</li>
                          <li>{setPrice(data?.priceBeforeDiscount)}</li>
                        </ul>
                        {!!data?.promoCode && !!data?.discountValue && (
                          <>
                            <ul className="flex align-items-center justify-content-between">
                              <li>Promo Code Value</li>
                              <li>{setPrice(data?.discount)}</li>
                            </ul>
                            <ul className="flex align-items-center justify-content-between">
                              <li>Total Order Value</li>
                              <li>{setPrice(data?.totalOrderAmount)}</li>
                            </ul>
                          </>
                        )}
                        {/* <ul className="flex align-items-center justify-content-between">
                          <li>Receipt</li>
                          <li>
                            <PR.Button
                              label="Download"
                              className="download-button"
                              icon="pi pi-download"
                            />
                          </li>
                        </ul> */}
                      </div>

                      <p className="heading-p mt-5">Transaction details</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>Payment method</li>
                          <li>{data?.paymentGateway}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Transaction ID</li>
                          <li>{data?.transactionId}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Status</li>
                          <li>
                            <span className="completed">
                              {data?.transactionStatus}
                            </span>
                          </li>
                        </ul>
                      </div>

                      <p className="heading-p mt-5">Customer details</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>Name</li>
                          <li>
                            {data?.firstName} {data?.lastName}
                          </li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Email</li>
                          <li>{data?.email}</li>
                        </ul>
                        {data?.phone?.localPhoneNumber && (
                          <ul className="flex align-items-center justify-content-between">
                            <li>Contact number</li>
                            <li>
                              {data?.phone?.callingCode +
                                " " +
                                data?.phone?.localPhoneNumber}
                            </li>
                          </ul>
                        )}
                      </div>

                      <p className="heading-p mt-5">Plan details</p>
                      <div className="order-details-block">
                        <ul className="flex align-items-center justify-content-between">
                          <li>Data plan</li>
                          <li>
                            {bundle?.isUnlimited
                              ? "Unlimited Data Plan"
                              : checkDataSize(bundle?.totalData)}
                          </li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Validity</li>
                          <li>{bundle?.description?.split("-")[0]}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Price</li>
                          <li>{setPrice(data?.dataPlans[0]?.price)}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Service Location</li>
                          <li>{bundle?.country?.split("(")[0]}</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>Service</li>
                          <li>Data Only</li>
                        </ul>
                        <ul className="flex align-items-center justify-content-between">
                          <li>SIM Type</li>
                          <li>e-SIM</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </section>
    </div>
  );
};

export default BundleDetails;
