import React, { useState, useRef, useMemo, useEffect } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom";
import * as PR from "../../prime-modules/index";
import { esimsICCID } from "../../services/api.jsx";
import { useDispatch, useSelector } from "react-redux";
import "./EsimDetails.scss";
import AdminFooter from "../layout/admin-footer";
import AdminHeader from "../layout/admin-header";
import { esimIcon, bundleIcon } from "../../assets/images";
import { formatDateTime, checkDataSize } from "../../utils/reuse";

const EsimDetails = () => {
  const { searchTerm } = useParams(); // Extract searchTerm from the URL
  const location = useLocation();
  const toast = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const adminData = useSelector((state) => state.adminAuth.adminSessionData);
  const headers = useMemo(() => {
    return { sessionid: adminData.sessionId };
  }, [adminData.sessionId]);
  const [esimData, setEsimData] = useState(location.state?.esimdata)
  const bundlesData = esimData?.activeBundlesData ? esimData?.activeBundlesData : []
  const [createLoading, setCreateLoading] = useState(false);
  const [checked, setChecked] = useState(false);
  const [iccidValue, setIccidValue] = useState("")


  useEffect(() => {
    if(!esimData){
      refreshEsim(true)
    }
  }, [])
  const createdTime = (rowData) => {
    return formatDateTime(rowData?.createdTs || "-")
  }

  const expiryOn = (rowData) => {
    return formatDateTime(rowData?.bundleEndTime || "-" )
  }

  const bundleDetails = (rowData) => {     
    return (
      <div className="expiry-column">
        <p>{`${rowData?.country?.split("(")[0]} ${
          rowData?.country ? "|" : ""
        } ${
          rowData?.isUnlimited
            ? "Unlimited"
            : `${checkDataSize(rowData?.remainingData)}  / ${checkDataSize(
                rowData?.totalData
              )} ${"left"}`
        } `}</p>
        </div>
        )
  }

  const filteredBundles = checked
    ? bundlesData.filter((bundle) => bundle.state === "expired")
    : bundlesData;

  const provider = () => {
    const providerMap = {
      1: 'eSIM-Go',
      2: 'Airalo',
      3: 'Cobira',
    };
  
    return providerMap[esimData?.providerCode] || 'N/A';
  };

  const price = (rowData) => {
    if (rowData?.totalOrderAmount === 0) {
      return rowData.totalOrderAmount;
    } else if (rowData?.totalOrderAmount) {
      return `$${rowData.totalOrderAmount}`;
    } else {
      return "-";
    }
  };  

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(() => {
        toast.current?.show({ severity: 'success', summary: 'Success', detail: 'ICCID has been copied to clipboard.' });
    }).catch(err => {
        toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Failed to copy ICCID.' });
    });
  }; 

  const refreshEsim = (firstTime) => {
    const now = new Date().getTime();
    if(firstTime !== true){
     const lastRefreshTime = localStorage.getItem("lastRefreshTime"); 
    if (lastRefreshTime && now - lastRefreshTime < 15 * 60 * 1000) {
      const remainingTime = Math.ceil((15 * 60 * 1000 - (now - lastRefreshTime)) / 1000);
      toast.current.show({
        severity: "warn",
        summary: "Warning",
        detail: `You can refresh again in ${Math.ceil(remainingTime / 60)} minutes.`,
      });
      return;
    }
    }
  
    setCreateLoading(true);  
    const getResponse = (response) => {
      setCreateLoading(false);
      if (response.result === "SUCCESS") {
        if(firstTime !== true){
          localStorage.setItem("lastRefreshTime", now);
          toast.current.show({
            severity: "success",
            summary: "Data Refreshed",
            detail: "The eSIM data has been refreshed successfully.",
          });
        }
        setEsimData(response.data)
      } else {
        const error = response.error;
        toast.current.show({
          severity: error.severity || "error",
          summary: "Error",
          detail: error.errorMsg || error.summary || "An unknown error occurred.",
        });
      }
    };
  
    esimsICCID(searchTerm, headers, dispatch, getResponse);
  };

  const handleSearch = () => {
    if (!iccidValue.trim()) {
      toast.current.show({
        severity: "warn",
        summary: "Validation Error",
        detail: "Please enter a valid ICCID.",
      });
      return;
    }

    setCreateLoading(true);
    const getResponse = (response) => {
      setCreateLoading(false);
      if (response.result === "SUCCESS") {
        setEsimData(response.data)
        navigate(`/esim-details/${iccidValue}`, { state: { esimdata: response.data } });
      } else {
        const error = response.error;
        toast.current.show({
          severity: error.severity || "error",
          summary: "Error",
          detail: error.errorMsg || error.summary || "An unknown error occurred.",
        });
      }
    };
    esimsICCID(iccidValue, headers, dispatch, getResponse);

  };

  const renderHeader = () => {
    return (
      <div className="flex align-items-center justify-content-between">
        <h2>eSIM Details</h2>
        <span className="p-input-icon-right esim-search-field">
            <i className="pi pi-search" onClick={handleSearch} />
            <PR.InputText placeholder="Search by ICCID" value={iccidValue}
                onChange={(e) => {
                  const numericValue = e.target.value.replace(/[^0-9]/g, "");
                  setIccidValue(numericValue)
                }}
                onKeyDown={(e) => {
                  if (e.key === "Enter" && !createLoading) {
                    handleSearch();
                  }
                }} 
                disabled={createLoading}
            />
        </span>
      </div>
    );
  };

  return (
    <>
      <div className="main">
        <div className="layout-sidebar">
          <AdminHeader />
        </div>
        <div className="layout-content-wrapper">
        {createLoading ? (
            <div className="col-12 text-center admin-userlist-section">
              <div className="progress-spinner">
                <PR.ProgressSpinner
                  className="progressSpinner"
                  animationDuration=".5s"
                />
              </div>
            </div>
          ) : (    
          <div className="esim-details-section">
                        {renderHeader()}
                        <div className="users-data-table">
                          <div className="iccid-section">
                            <h3>
                              ICCID - <span>{searchTerm}</span>
                              <PR.Button icon="pi pi-copy" className="p-button-text copy-btn" onClick={() => copyToClipboard(searchTerm)} />
                            </h3>
                            <div className="status-section">
                              <p>eSIM Status: <span className="status-badge released">{esimData?.status || "-"}</span></p>
                              {/* <PR.Button icon="pi pi-ellipsis-h" className="p-button-text copy-btn" /> */}
                            </div>
                          </div>
            
                          <div className="esim-details-accordion">
                              <div className="flex align-items-center gap-2 w-full mb-3">
                                <PR.Image src={esimIcon} alt="Logo" />
                                <span className="font-bold white-space-nowrap">eSIM details</span>
                              </div>
                              <div className="details-form">
                                <div className="top-block">
                                  <div>
                                      PIN <strong>{esimData?.pin || "-"}</strong>
                                  </div>
                                  <div>
                                      PUK <strong>{esimData?.puk || "-"}</strong>
                                  </div>
                                  <div>
                                    Profile <strong>{esimData?.status || "-"}</strong>
                                  </div>
                                  <div>
                                      First Install Date <strong>{esimData?.firstInstallDate || "-"}</strong> 
                                  </div>
                                  <div>
                                      SMDP+ Address <strong>{esimData?.smdpAddress || "-"}</strong> 
                                  </div>
                                  <div>
                                      SMDP-ID <strong>{esimData?.matchingId || "-"}</strong> 
                                  </div>
                                  <div>
                                      Latest eSIM Status<strong> <i className="pi pi-exclamation-triangle mr-2"></i> {esimData?.esimStatus || "No data available"}</strong>
                                  </div>
                                  {!(esimData?.providerCode === 2 || esimData?.providerCode === 3) &&
                                  <div>
                                  <PR.Tooltip
                                      target=".refresh-button"
                                      content="Refresh eSIM will instruct the roaming network to disconnect the eSIM and re-register. This will often resolve roaming issues your customers are experiencing."
                                      position="top"
                                      className="custom-tooltip"
                                    />
                                  <PR.Button label="Refresh eSIM" icon="pi pi-refresh" className="refresh-button" onClick={refreshEsim} loading={createLoading}/>
                                  </div>
                                  }
                                </div>
                                {/* <div className="bottom-block">
                                    <div className="relative">
                                      <label>Send SMS Notification (0/160 Characters)</label>
                                      <PR.InputTextarea placeholder="Enter your message..." className="message-textarea" rows={4} maxLength={160} />
                                      <PR.Button label="Send" className="send-button" />
                                    </div> 
                                </div> */}
                              </div>
            
                              <div className="flex align-items-center gap-2 w-full mb-3 mt-5 justify-content-between">
                                <div className="flex align-items-center gap-2">
                                  <PR.Image src={bundleIcon} alt="Logo" />
                                  <span className="font-bold white-space-nowrap"> Associated Bundles</span>
                                </div>
                                <div className="switch-toggle">
                                  <PR.InputSwitch checked={checked} onChange={(e) => setChecked(e.value)} />
                                  <span>Expired bundle</span>
                                </div>
                              </div>
                              <PR.DataTable value={filteredBundles}>
                                <PR.Column field="providerName" header="Provider Name" body={provider} ></PR.Column>
                                <PR.Column field="description" header="Bundle Name"></PR.Column>
                                <PR.Column field="bundleUsage" header="Bundle Usage" body={(rowData) => bundleDetails(rowData)} ></PR.Column>
                                <PR.Column field="createdTs" header="Purchased On" body={createdTime} ></PR.Column>
                                <PR.Column field="bundleEndTime" header="Expiry On" body={(rowData) => expiryOn(rowData)} ></PR.Column>
                                <PR.Column field="totalOrderAmount" header="Price" body={price}></PR.Column>
                              </PR.DataTable>
                          </div>
                        </div>
          </div>)}
      
          <AdminFooter />
        </div>
        <PR.Toast ref={toast} position='top-right' />
      </div>
    </>
  );
};

export default EsimDetails;
