import React, {useEffect, useMemo, useRef, useState} from "react";
import * as PR from "../../prime-modules/index";
import { useNavigate, Link } from 'react-router-dom';
import { globalConfig } from "../../GlobalConfig";
import { useFormik } from "formik";
import * as Yup from 'yup';
import * as utils from '../../utils';
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getPriceFilters } from "../../services/api";
import { orderStatuses, paymentGatways, formatDateTime, formatPromoCode } from "../../utils/reuse";

const OrdersList = (props) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const toast = useRef();
    const calendarRef = useRef(null);
    const adminData = useSelector(state => state.adminAuth.adminSessionData);
    const headers = useMemo(() => {
        return { sessionid: adminData.sessionId };
    }, [adminData.sessionId]);

    const currentDate = new Date();

    const [providerList, setProviderList] = useState([]);

    useEffect(() => {
        getPriceFilters(headers, dispatch, response => {
            if (response.result === 'SUCCESS') {
                const providers = response.data?.providers?.length > 0 ? response.data?.providers.map(provider => ({ label: provider, value: provider })).sort((a, b) => a.label.localeCompare(b.label)): [];
                setProviderList(providers);
            } else {
                const error = response?.error;
                toast.current?.show({ severity: error.severity, summary: 'Error', detail: (error?.errorMsg) ? error?.errorMsg : error?.summary })
            }
        })
    }, [dispatch, headers]);

    const validationSchema = Yup.object().shape({
        searchCustomer: Yup.string().trim(),
        searchAffiliateUser: Yup.string().trim(),
    })

    const resetOrderForm = () => {
        props.setCustomer('');
        props.setOrderId('')
        props.setAffiliateUser('');
        props.setPurchaseDate(props.nVal);
        props.setPurchaseDateFrom(props.nVal);
        props.setPurchaseDateTo(props.nVal);
        props.setPaymentGateway([]);
        props.setPaymentStatus([]);
        props.setOrderStatus([]);
        props.setProviderName([]);
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setOrdersData(true);
    }


    const handleSubmit = (formData) => {
        props.setCustomer(trimFormData(formData.searchCustomer));
        props.setOrderId(trimFormData(formData.searchOrderId).replace(/[^a-zA-Z0-9]/g, '').toLowerCase());
        props.setAffiliateUser(trimFormData(formData.searchAffiliateUser));
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setOrdersData(true);
    }

    const trimFormData = (val) => {
        return val.trim();
    }


    const formik = useFormik({
        initialValues: {
            searchCustomer: props.customer,
            searchOrderId: props.orderId,
            searchProvider: props.providerName,
            searchAffiliateUser: props.affiliateUser,
            searchPurchaseDate: props.purchaseDate,
            searchPaymentGateway:props.paymentGateway,
            searchPaymentStatus:props.paymentStatus,
            searchOrderStatus:props.orderStatus
        },
        validationSchema: validationSchema,
        enableReinitialize: true,
        onSubmit: handleSubmit
    })

    const handleSelectedDate = (e) => {
        if (e.value === props.nVal) {
            props.setPurchaseDate(props.nVal);
            props.setPurchaseDateFrom(props.nVal);
            props.setPurchaseDateTo(props.nVal);
        } else {
            const date = e.value;
            const fromDate = utils.modifyDate(date[0]);
            const toDate = (date[1] === props.nVal) ? fromDate : utils.modifyDate(date[1]);
            props.setPurchaseDate(date);
            props.setPurchaseDateFrom(fromDate);
            props.setPurchaseDateTo(toDate);

            // close the calendar if from and to date is selected
            if (date?.length > 1 && date[1] != null) {
                calendarRef.current.hide()
            }
        }
        props.setOrdersData(false);
    }

    const handleProviderChange = (val) => {
        props.setProviderName(val);
        props.setOrdersData(false);
    }

    const handleSelectedOrderStatus = (val) => {
        props.setOrderStatus(val);
        props.setOrdersData(false);
    }

    const handleSelectedPaymentGateway = (val) => {
        props.setPaymentGateway(val);
        props.setOrdersData(false);
    }

    const statusBgColor = (status) => {
        const statusVal = orderStatusList?.filter(val => val.value === status)[0]?.label;
        return (
            <p>
                <span
                    className={`status ${status?.toLowerCase()}`}
                >{statusVal ?? status ?? 'N/A'}</span>
            </p>
        );
    };
    const usenameTemplate = rowData => {
        const userOrders = '/orders/' + rowData.userId;
        return (
            props.screen === 'user-orders'
                ? rowData.username
                : <Link to={userOrders} className="underline">{rowData.username}</Link>
        )
    };

    const copyToClipboard = (text) => {
        navigator.clipboard.writeText(text).then(() => {
            toast.current?.show({ severity: 'success', summary: 'Success', detail: 'Order ID has been copied to clipboard.' });
        }).catch(err => {
            toast.current?.show({ severity: 'warn', summary: 'Warning', detail: 'Failed to copy order ID.' });
        });
    };   

    const geteSIMsDescription = (esim) => {
        const countryName = esim?.countries?.length ? esim?.countries[0]?.country?.name?.split("(")[0] : '';
        const description = countryName && esim.dataAmountForDisplay ? `${countryName} - ${esim.dataAmountForDisplay}`: esim.description;
        return description;
    }

    const itemTemplate = (rowData) => {
        return (
            <div className="col-12 custom-bg">
                <div className="custom-table-body">
                    <div className="table-grid">
                        <ul className="col-ul">
                            <li className="customer">{usenameTemplate(rowData)}</li>
                            <li className="orderId">{rowData.orderId.substring(0,8)}</li>
                            <li className="bundleName">{geteSIMsDescription(rowData?.dataPlans.length && rowData.dataPlans[0])}</li>
                            <li className="price">
                                {rowData.priceBeforeDiscount > 0 && (rowData.priceBeforeDiscount !== rowData.totalOrderAmount) && (
                                    <>
                                        <s className="red-color" data-title={rowData.promoCode ? `${rowData.paymentGateway === 'prepaid' ? 'Voucher Code' : 'Promo Code'}: ${formatPromoCode(rowData.promoCode, (rowData.paymentGateway === 'prepaid'))}` : ""}>
                                            {utils.setPrice(rowData.priceBeforeDiscount)}
                                        </s>
                                    </>
                                )}
                                 <div {...(rowData.promoCode && { 'data-title': `Promocode: ${rowData.promoCode}` })}>
                                    {utils.setPrice(rowData.totalOrderAmount)}
                                </div>
                            </li>
                            <li className="providerName">{rowData.providerName}</li>
                            <li className="createdTs">{formatDateTime(rowData.createdTs)}</li>                       
                            <li className="paymentGateway">{rowData.paymentGateway ? utils.firstLetterCapital(rowData.paymentGateway) : "-"}</li>
                            <li className="paymentStatus">{statusBgColor(rowData?.paymentStatus)}</li>
                            <li className="orderStatus">{statusBgColor(rowData?.orderStatus)}</li>
                            <li className="affiliateUser">{rowData.affiliateUser}</li>
                            <li className="orderActions">
                                <div className="copyOrderId" onClick={() => copyToClipboard(rowData.orderId)}> 
                                    <PR.Button 
                                        icon="pi pi-copy" 
                                        className="p-button-text icon-button"
                                        tooltip="Copy Order ID"
                                        tooltipOptions={{ position: 'top' }}
                                        style={{ color: 'red', fontSize: '1.5rem' }}
                                    />
                                </div>
                                <div className="orderDetailArrow" onClick={() => navigate('/' + props.screen + '/details/' + rowData.orderId)}
                                >
                                    <PR.Button 
                                        icon= "pi pi-chevron-right"
                                        className="p-button-text icon-button"
                                        tooltip="View Order Details"
                                        tooltipOptions={{ position: 'top' }}
                                        style={{ color: 'red', fontSize: '1.5rem' }}
                                    />
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        );
    };

    const paginate = (currentPage) => {
        props.setPaging(false);
        props.setPage(currentPage);
        const offsetVal = (currentPage - 1) * props.pageCount;
        const limitVal = props.pageCount + 1;
        props.setOffset(offsetVal);
        props.setLimit(limitVal);
        props.setOrdersData(true);
        props.setListingType('list');
    };

    const onSortChange = (value) => {
        if (value.indexOf("!") === 0) {
            props.setSortOrder(-1);
            props.setSortField(value.substring(1, value.length));
            props.setSortKey(value);
        } else {
            props.setSortOrder(1);
            props.setSortField(value);
            props.setSortKey(value);
        }
        props.setPage(1);
        props.setOffset(0);
        props.setLimit(props.pageCount + 1);
        props.setListingType('list');
        props.setOrdersData(true);
    };

    const handleAffiliateOptions = (customers = []) => {
        return (Array.isArray(customers) ? customers : [])
          .map(customer => customer.trim())
            .sort((a, b) => a.localeCompare(b));
    }; 

    const ordersDataHeader = (field, label, selector, sortable = true) => {
        return (
            sortable ? 
            (props.sortField !== field) ?
                <>
                    <li onClick={(e) => onSortChange(field)} className={selector}><span>{label}<i className="pi pi-sort-alt"></i></span> </li>
                </> :
                <>
                    {(props.sortKey === field) ?
                        <>
                            <li onClick={(e) => onSortChange('!' + field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-up-alt"></i></span> </li>
                        </> :
                        <li onClick={(e) => onSortChange(field)} className={selector}><span className="selectedList">{label}<i className="pi pi-sort-amount-down"></i></span> </li>
                    }
                </>
                 : 
            <li className={selector} style={{ pointerEvents: 'none'}}>
                 <span>{label}</span> 
            </li>
        );
    }

    const orderStatusList = orderStatuses;
    const paymentGatewayOptions = paymentGatways;

    const handleCustomer = (e) =>{
       props.setCustomer(e.target.value)
       formik.handleChange(e)
       props.setOrdersData(false);
    }
    const handleOrderId = (e) =>{
        props.setOrderId(e.target.value)
        formik.handleChange(e)
        props.setOrdersData(false);
     }
    const handleAffiliateName = (e) =>{
        props.setAffiliateUser(e.target.value)
        formik.handleChange(e)
        props.setOrdersData(false);
    }
    

    return (
        <section className="admin-orderlist-section">
            <div className="grid grid-nogutter">
                <div className="col-12">
                    <div className="heading-sec align-items-center justify-content-between">
                        <div className="flex align-items-center justify-content-between mb-5">
                            <div className="right flex align-items-center ">
                                <h2>Orders</h2>
                                <div className="breadcrumb-section">
                                    <PR.BreadCrumb model={props.items} home={props.home} />
                                </div>
                            </div>
                            <div className="right-section flex align-items-right gap-3" >
                                <PR.Tooltip target=".exportBtn" content={!props.purchaseDate ? 'Select Date Range to Export CSV' : undefined} position="top" showOnDisabled/>
                                <PR.Button label="Export CSV" className="exportBtn" icon="pi pi-file-export" iconPos="left" onClick={() => props.setListingType('export')} 
                                    disabled={!props.purchaseDate || props?.loading}
                                />
                           </div>
                        </div>
                        <div className="filter-right">
                            <form autoComplete="off" onSubmit={formik.handleSubmit}>
                                <div className='flex align-items-center'>
                                    <div className="users-search-input">
                                        <span>
                                            <PR.InputText  placeholder="Customer" name="searchCustomer" onChange={handleCustomer} onBlur={formik.handleBlur} value={formik.values.searchCustomer} autoComplete="off" />
                                        </span>
                                        {formik.errors.searchCustomer && formik.touched.searchCustomer
                                            ? <div className='error-message'>{formik.errors.searchCustomer}</div>
                                            : ''
                                        }
                                    </div>

                                    <div className="users-search-input">
                                        <span>
                                            <PR.InputText  placeholder="Order ID" name="searchOrderId" onChange={handleOrderId} onBlur={formik.handleBlur} value={formik.values.searchOrderId} autoComplete="off" />
                                        </span>
                                    </div>

                                    <div className="users-search-input order-status">
                                        <span>
                                            <PR.MultiSelect value={props.providerName} options={providerList} onChange={(e) => handleProviderChange(e.value)} name="searchProvider" optionLabel="label" placeholder="Provider" maxSelectedLabels={1} />
                                        </span>
                                    </div>


                                    <div className="users-search-input">
                                        <span>
                                            <PR.MultiSelect value={props.paymentGateway} options={paymentGatewayOptions} onChange={(e) => handleSelectedPaymentGateway(e.value)} name="searchPaymentGateway" optionLabel="label" placeholder="Payment Method" maxSelectedLabels={1} />
                                        </span>
                                        {formik.errors.searchPaymentGateway && formik.touched.searchPaymentGateway
                                            ? <div className='error-message'>{formik.errors.searchPaymentGateway}</div>
                                            : ''
                                        }
                                    </div>

                                    <div className="users-search-input order-status">
                                        <span>
                                            <PR.MultiSelect filter resetFilterOnHide value={props.orderStatus} options={orderStatusList} onChange={(e) => handleSelectedOrderStatus(e.value)} name="searchOrderStatus" optionLabel="label" placeholder="Order Status" maxSelectedLabels={1} />
                                        </span>
                                        {formik.errors.searchOrderStatus && formik.touched.searchOrderStatus
                                            ? <div className='error-message'>{formik.errors.searchOrderStatus}</div>
                                            : ''
                                        }
                                    </div>

                                    <div className="users-search-input affiliate-dropdown">
                                        <span>
                                            <PR.Dropdown filter resetFilterOnHide placeholder="Affiliate Name" name="searchAffiliateUser" options={handleAffiliateOptions(props.affiliateNames)} onChange={handleAffiliateName} onBlur={formik.handleBlur} value={formik.values.searchAffiliateUser} autoComplete="off" />
                                        </span>
                                        {formik.errors.searchAffiliateUser && formik.touched.searchAffiliateUser
                                            ? <div className='error-message'>{formik.errors.searchAffiliateUser}</div>
                                            : ''
                                        }
                                    </div>


                                    <div className="users-search-input">
                                        <span>
                                        <PR.Calendar value={props.purchaseDate} onChange={handleSelectedDate} selectionMode="range" readOnlyInput
                                            placeholder="Date Range" name="searchPurchaseDate" dateFormat="dd-mm-yy" maxDate={currentDate} showIcon showButtonBar numberOfMonths={2} ref={calendarRef}/>
                                        </span>
                                        {formik.errors.searchPurchaseDate && formik.touched.searchPurchaseDate
                                            ? <div className='error-message'>{formik.errors.searchPurchaseDate}</div>
                                            : ''
                                        }
                                    </div>

                                    <div className="users-search-input last-buttons-block">
                                        <span>
                                            <PR.Button label="Search" type='submit' className="searchBtn" />
                                            <PR.Button label="Reset" type='reset' className="resetBtn" onClick={resetOrderForm} />
                                        </span>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
            <div className="users-data-table card">
                <div className="card datatable-scroll">
                    <div className="custom-table">
                        <div className="custom-table-header">
                            <div className="table-grid">
                                <ul className="col-ul sorting-li">
                                    {ordersDataHeader('name', 'Customer', 'customer')}
                                    {ordersDataHeader('orderId', 'Order ID', 'orderId')}
                                    {ordersDataHeader('bundleName', 'Bundle Name', 'bundleName', false)}
                                    {ordersDataHeader('totalOrderAmount', 'Total Price', 'price')}
                                    {ordersDataHeader('providerName', 'Provider', 'providerName')}
                                    {ordersDataHeader('createdTs', 'Date & time', 'purchasedOn')}
                                    {ordersDataHeader('paymentGateway', 'Payment Method', 'paymentGateway')}
                                    {ordersDataHeader('paymentStatus', 'Payment Status', 'paymentStatus')}
                                    {ordersDataHeader('orderStatus', 'Order Status', 'orderStatus')}
                                    {ordersDataHeader('affiliateName', 'Affiliate Name', 'affiliateUser')}
                                    {ordersDataHeader('actions', 'Actions', 'actions')}
                                </ul>
                            </div>
                        </div>
                        <PR.DataView
                            loading={props.loading}
                            value={props.orders}
                            itemTemplate={itemTemplate}
                        />
                    </div>
                </div>
            </div>
            {props.orders.length > 0 ? (
                <div className="pagination">
                    <button
                        type="button"
                        onClick={() => paginate(props.page - 1)}
                        className={props.paging ? props.page <= 1 ? "disabled" : "pagination-button" : "disabled"}
                    >
                        {globalConfig.pagination_Previous}
                    </button>
                    <span className="active"> {props.page} </span>
                    <button
                        type="button"
                        onClick={() => paginate(props.page + 1)}
                        className={props.paging ? props.last ? "disabled" : "pagination-button": "disabled"}
                    >
                        {globalConfig.pagination_Next}
                    </button>
                </div>
            ) : (
                <></>
            )}
            <PR.Toast ref={toast} position='top-right' />
        </section>
    );
}

export default OrdersList;